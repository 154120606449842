import React, { Dispatch, useEffect, useState } from 'react';

import {
    RestaurantDetail,
    OrderType,
    Coupon,
    getCityDisplay,
    SelectionGroupType,
    DeliveryPlaceSimple,
    Order,
} from '@bestelleck/utils';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Snackbar, useMediaQuery, Backdrop, Button, CircularProgress, Alert, AlertTitle } from '@mui/material';
import { captureException } from '@sentry/react';
import { FormProvider, useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';

import AddressDialog from '../../components/AddressDialog/AddressDialog';
import { setContact, setOrder } from '../../redux/app/app.actions';
import { CartItem } from '../../redux/cart/types';
import { setPlace } from '../../redux/orderType/orderType.actions';
import { RootState } from '../../redux/store';
import { CreateOrder, CreateOrderSelectionGroupExtraGroup, CreateOrderSelectionGroupOption } from '../../types/Order';
import { baseUrl, version } from '../../util/constants';
import { useTrackView } from '../../util/tracking/trackPage';
import {
    calculateIsOpen,
    calculatePrice,
    customFetch,
    formatPrice,
    isPreOrderCurrentlyPossible,
} from '../../util/utils';
import CartComponent from '../Restaurant/Cart/Cart';

import styles from './Checkout.module.scss';
import { getTime } from './Checkout.util';
import { CouponComponent } from './Form/Coupon/Coupon';
import { CheckoutForm } from './Form/Form';
import { NewsletterCheckox } from './NewsletterCheckbox/NewsletterCheckbox';
import { PaymentComponent } from './Payment/Payment';
import { ApplePay } from './Stripe/ApplePay/ApplePay';
import { StripeCheckout } from './Stripe/StripeCheckout';

export type FormResult = {
    address: string;
    name: string;
    email: string;
    phone: string;
    deliveryTime: number;
    notes: string;
    preOrderTime: string;
    houseNumber: string;
    coupon: string;
    newsletter: boolean;
};

const CheckoutComponent: React.FC = () => {
    const methods = useForm({ mode: 'onBlur' });
    const { id } = useParams<{ id: string }>();
    const { trackEvent } = useMatomo();
    const carts = useSelector((state: RootState) => state.cart.carts, shallowEqual);

    const { orderType } = useSelector((state: RootState) => state.orderType, shallowEqual);
    const { place } = useSelector((state: RootState) => state.orderType, shallowEqual);

    const selectedPaymentOption = useSelector((state: RootState) => state.app.selectedPaymentOption, shallowEqual);

    useTrackView(`Restaurant_${id}_checkout`);

    const [openStripe, setOpenStripe] = useState({
        open: false,
        payment: '',
        clientSecret: '',
        orderId: '',
        restaurantId: '',
    });

    const [restaurant, setRestaurant] = useState<RestaurantDetail>();
    const [openAdressDialog, setOpenAdressDialog] = useState(false);
    const [coupon, setCoupon] = useState<Coupon>();

    const [message, setMessage] = useState({ isError: false, message: '' });
    const [isLoading, setIsLoading] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:750px)');

    let url = `${baseUrl}/restaurants/${id}?useNewScheduleDays=true&useSelectionGroups=true&orderType=${orderType}`;

    if (place.lat !== '' && place.lon !== '') {
        url = `${url}&latitude=${place.lat}&longitude=${place.lon}`;
    }

    useEffect(() => {
        return () => {
            setIsLoading(false);
        };
    }, []);

    useEffect(() => {
        setIsLoading(true);
        fetch(url)
            .then((res) => res.json())
            .then(
                (result) => {
                    setRestaurant(result);
                    setIsLoading(false);
                },
                (error) => {
                    captureException(error);
                    setIsLoading(false);
                },
            );
    }, [url]);

    useEffect(() => {
        if (message.message !== '') {
            setTimeout(() => setMessage({ isError: false, message: '' }), 6000);
        }
    }, [message]);

    const dispatch: Dispatch<any> = useDispatch();
    const history = useHistory();
    const watchAllFields = methods.watch();

    const isDelivery = orderType === OrderType.Delivery;

    let menuCoupon;
    if (!isDelivery) {
        menuCoupon = restaurant?.menu.coupon?.pickup;
    } else if (isDelivery) {
        menuCoupon = restaurant?.menu.coupon?.delivery;
    }
    const isOpen = restaurant ? calculateIsOpen(restaurant, orderType) : false;
    const preOrderPossible = isPreOrderCurrentlyPossible(restaurant, isDelivery, isOpen);
    const isPreOrder = preOrderPossible && !isOpen ? true : false;
    const foundCart = carts?.find((cart: { restaurantId: string; items: CartItem[] }) => cart.restaurantId === id);
    const items: readonly CartItem[] = foundCart ? foundCart.items : [];

    let delivery: DeliveryPlaceSimple | undefined;
    if (isDelivery && restaurant?.delivery.place) {
        delivery = restaurant.delivery.place;
    }
    useEffect(() => {
        if (!delivery && isDelivery && restaurant?.delivery) {
            dispatch(
                setPlace({
                    place: {
                        boundingbox: [],
                        class: '',
                        display_name: '',
                        icon: '',
                        importance: 0,
                        lat: '',
                        licence: '',
                        lon: '',
                        osm_id: 0,
                        osm_type: '',
                        place_id: 0,
                        type: '',
                    },
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delivery, isDelivery, restaurant]);

    const deliveryFee = delivery?.fee ? delivery.fee : 0;

    const minimumOrderValue = delivery ? delivery.minimumOrderValue : 0;
    const { price, subtotal, discount, couponDiscount } = calculatePrice({
        items,
        orderType,
        deliveryCost: deliveryFee,
        menuCoupon,
        feeThreshold: delivery?.freeThreshold,
        coupon,
    });

    const totalWithDiscount = subtotal - couponDiscount - discount;
    let isMinimumOrderValue = minimumOrderValue <= totalWithDiscount;
    if (coupon && coupon.ignoreMinimumOrderValue) {
        isMinimumOrderValue = minimumOrderValue <= subtotal - discount;
    }

    const isAppleOrGooglePay = selectedPaymentOption === 'applepay' || selectedPaymentOption === 'googlepay';

    const orderUrl = `${baseUrl}/orders?useSelectionGroups=true`;

    const onSubmit = async (
        data: FormResult | { [x: string]: any },
    ): Promise<{ orderId: string; clientSecret: string } | undefined> => {
        if (!restaurant) return;
        setIsLoading(true);
        let preferredTime;
        if (!isPreOrder && data.deliveryTime) {
            preferredTime = getTime(data.deliveryTime);
        } else if (isPreOrder && data.preOrderTime) {
            preferredTime = getTime(data.preOrderTime);
        }

        const paymentOption = selectedPaymentOption;
        const isOpenOnSubmit = calculateIsOpen(restaurant, orderType);

        let newOrder: CreateOrder = {
            contact: {
                email: data.email.trim(),
                fullName: data.name.trim(),
                phoneNumber: data.phone.trim(),
            },
            restaurantId: id,
            calculatedPrice: Math.round((price + Number.EPSILON) * 100) / 100,
            items: items.map((item) => {
                const filteredExtraGroups = item.extraGroups.filter((extraGroup) => extraGroup.extras.length > 0);
                const extraGroups: CreateOrderSelectionGroupExtraGroup[] = filteredExtraGroups.map((extraGroup) => ({
                    id: extraGroup.id,
                    extras: extraGroup.extras.map((extra) => extra.id),
                    type: SelectionGroupType.ExtraGroup,
                }));
                const options: CreateOrderSelectionGroupOption[] = item.options.map((option) => {
                    return { selectedValue: option.selectedValue.id, type: SelectionGroupType.Option, id: option.id };
                });
                return {
                    amount: item.amount,
                    id: item.id,
                    note: item.note || undefined,
                    selectionGroups: [...options, ...extraGroups],
                };
            }),
            note: data.notes,
            type: orderType,
            preferredTime: preferredTime ? preferredTime : undefined,
            coupon: coupon?.key,
            isPreOrder: preOrderPossible && !isOpenOnSubmit ? true : false,
            payment: {
                option: paymentOption,
            },
            source: 'web',
            paypalReturnUrl:
                paymentOption === 'paypal' ? `${window.location.origin}/${restaurant.id}/completed` : undefined,
            subscribeToNewsletter: data.newsletter,
        };

        if (orderType === OrderType.Delivery && delivery?.locationId) {
            newOrder = {
                ...newOrder,
                delivery: {
                    city: getCityDisplay(place),
                    postalCode: place.address?.postcode as string,
                    streetName: data.address.trim(),
                    houseNumber: data.houseNumber.trim(),
                    locationId: delivery?.locationId,
                    latitude: place.lat,
                    longitude: place.lon,
                },
            };
        }

        trackEvent({ action: 'Submit Cart', category: 'Checkout' });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'X-Web-Version': version || '0.0.0' },
            body: JSON.stringify(newOrder),
        };
        try {
            const result = await customFetch<Order>(orderUrl, requestOptions);
            dispatch(setOrder({ order: result }));
            if (paymentOption === 'cash') {
                history.push(`/${restaurant.id}/completed?orderId=${result.id}`);
            }
            if (result.paypalApproveUrl) {
                window.location.href = result.paypalApproveUrl;
            }

            if (result.stripeClientSecret !== undefined && result.stripeClientSecret !== '') {
                if (paymentOption === 'applepay') {
                    return { orderId: result.id, clientSecret: result.stripeClientSecret };
                } else {
                    setOpenStripe({
                        open: true,
                        payment: paymentOption,
                        clientSecret: result.stripeClientSecret,
                        orderId: result.id,
                        restaurantId: restaurant.id,
                    });
                    setIsLoading(false);
                }
            }
            trackEvent({ action: 'Order Successful', category: 'Checkout' });
            dispatch(setPlace({ place }));
        } catch (error: any) {
            setIsLoading(false);
            setMessage({ isError: true, message: `Es ist ein Fehler aufgetreten: ${error.message}` });
            trackEvent({ action: 'Error', category: 'Checkout' });
            captureException(error);
        }
    };

    return (
        <div className={styles.Checkout}>
            <div className={styles.main}>
                <FormProvider {...methods}>
                    <form
                        noValidate
                        autoComplete="off"
                        name="checkout"
                        className={styles.form}
                        onBlur={() => {
                            if (
                                watchAllFields.name !== undefined &&
                                watchAllFields.phone !== undefined &&
                                watchAllFields.email !== undefined
                            ) {
                                dispatch(setContact({ contact: watchAllFields }));
                            }
                        }}
                    >
                        <CheckoutForm
                            restaurant={restaurant}
                            isPreOrder={isPreOrder}
                            setOpenAdressDialog={setOpenAdressDialog}
                        />
                        <h3>Wie möchtest Du bezahlen?</h3>
                        {restaurant && (
                            <div className={styles.payment}>
                                <PaymentComponent paymentOptions={restaurant.payment.paymentOptions}></PaymentComponent>
                            </div>
                        )}

                        {restaurant && (
                            <CouponComponent
                                paymentMethod={selectedPaymentOption}
                                setMessage={setMessage}
                                setCoupon={setCoupon}
                                coupon={coupon}
                            />
                        )}
                        {isSmallScreen && restaurant && (
                            <>
                                <h3>Zusammenfassung</h3>
                                <div className={styles.cartSmall}>
                                    <CartComponent
                                        restaurant={restaurant}
                                        showButton={false}
                                        coupon={coupon}
                                        setCoupon={setCoupon}
                                        setMessage={setMessage}
                                        readonly={true}
                                    ></CartComponent>
                                </div>
                            </>
                        )}

                        <NewsletterCheckox />

                        {(isOpen || preOrderPossible) &&
                            foundCart &&
                            !isAppleOrGooglePay &&
                            isMinimumOrderValue &&
                            foundCart.items.length > 0 && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={methods.handleSubmit(onSubmit)}
                                    className={styles.orderButton}
                                >
                                    Bestellung abschließen ({formatPrice(price)})
                                </Button>
                            )}
                        {(isOpen || preOrderPossible) &&
                            foundCart &&
                            restaurant &&
                            isAppleOrGooglePay &&
                            isMinimumOrderValue &&
                            foundCart.items.length > 0 && (
                                <ApplePay
                                    getClientSecret={onSubmit}
                                    price={price}
                                    restaurantId={restaurant.id}
                                    setIsLoading={setIsLoading}
                                    data={watchAllFields}
                                    formIsValid={methods.formState.isValid}
                                    triggerValidation={() =>
                                        methods.trigger(['houseNumber', 'name', 'email', 'phone', 'address', 'city'], {
                                            shouldFocus: true,
                                        })
                                    }
                                />
                            )}
                        {(isOpen || preOrderPossible) && foundCart?.items.length === 0 && (
                            <Button variant="contained" color="primary" disabled={true} className={styles.orderButton}>
                                Dein Warenkorb ist leer
                            </Button>
                        )}
                        {(isOpen || preOrderPossible) &&
                            foundCart?.items &&
                            foundCart.items.length > 0 &&
                            !isMinimumOrderValue && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={true}
                                    className={styles.orderButton}
                                >
                                    Der Mindestbestellwert von {formatPrice(minimumOrderValue)} wurde noch nicht
                                    erreicht
                                </Button>
                            )}

                        {!isOpen && !preOrderPossible && (
                            <Button variant="contained" color="primary" disabled={true} className={styles.orderButton}>
                                Das Restaurant ist bereits geschlossen
                            </Button>
                        )}

                        <div className={styles.disclaimer}>
                            Durch Anklicken von Zahlungspflichtig bestellen bestätigst Du den Warenkorb und Deine
                            eingegebenen Daten und stimmst unseren{' '}
                            <Link to="/datenschutz" target="_blank">
                                Datenschutzbestimmungen
                            </Link>{' '}
                            sowie{' '}
                            <Link target="_blank" to="/agb">
                                AGB
                            </Link>{' '}
                            zu.
                        </div>
                    </form>
                </FormProvider>
            </div>
            <div className={styles.cart}>
                {!isSmallScreen && restaurant && (
                    <CartComponent
                        restaurant={restaurant}
                        showButton={false}
                        coupon={coupon}
                        setCoupon={setCoupon}
                        setMessage={setMessage}
                    ></CartComponent>
                )}
            </div>
            <Snackbar
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                open={message.message !== ''}
                autoHideDuration={6000}
                message={message.message}
            >
                <Alert severity={message.isError ? 'error' : 'success'}>
                    {message.isError && <AlertTitle>Fehler</AlertTitle>}
                    {message.message}
                </Alert>
            </Snackbar>

            <Backdrop sx={{ color: '#fff' }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {restaurant && openAdressDialog && (
                <AddressDialog
                    open={openAdressDialog}
                    restaurant={restaurant}
                    allowManualEntry={true}
                    handleClose={() => {
                        setOpenAdressDialog(false);
                    }}
                ></AddressDialog>
            )}
            {openStripe.open && (
                <StripeCheckout
                    isOpen={openStripe.open}
                    price={price}
                    payment={openStripe.payment}
                    clientSecret={openStripe.clientSecret}
                    setIsOpen={(isOpen) => {
                        setOpenStripe({ ...openStripe, open: isOpen });
                    }}
                    orderId={openStripe.orderId}
                    restaurantId={openStripe.restaurantId}
                />
            )}
        </div>
    );
};

export default CheckoutComponent;
