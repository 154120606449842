import { OrderType } from '@bestelleck/utils';
import { Dispatch } from 'redux';

import { baseUrl } from '../../util/constants';

import { getRestaurantsError, getRestaurantsPending, getRestaurantsSuccess } from './restaurant.actions';
import { RestaurantAction } from './restaurant.type';

export const fetchRestaurants = (
    lat?: string,
    lon?: string,
    orderType?: string,
): ((dispatch: Dispatch<RestaurantAction>) => void) => {
    let url = `${baseUrl}/restaurants?useNewScheduleDays=true`;
    if (lat !== '') {
        url = `${url}&latitude=${lat}`;
    }
    if (lon !== '') {
        url = `${url}&longitude=${lon}`;
    }
    if (orderType) {
        url = `${url}&orderType=${orderType}`;
    }

    if (orderType === OrderType.Delivery && (lat === '' || lon === '')) {
        return (dispatch: Dispatch<RestaurantAction>) => {
            dispatch(getRestaurantsSuccess([]));
        };
    }

    return (dispatch: Dispatch<RestaurantAction>) => {
        dispatch(getRestaurantsPending());
        fetch(url)
            .then((res) => res.json())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(getRestaurantsSuccess(res));
                return res;
            })
            .catch((error: Error) => {
                dispatch(getRestaurantsError(error));
            });
    };
};
