import { OrderType } from '@bestelleck/utils';
import { AnyAction } from '@reduxjs/toolkit';

import { checkOrderType, setCheckedOrderType, setOrderType, setPlace, setPlaceAndType } from './orderType.actions';
import { OrderTypeState } from './types';

const initialState: OrderTypeState = {
    orderType: OrderType.PickUp,
    needToCheck: false,
    hasBeenChecked: false,
    showDialog: false,
    place: {
        boundingbox: [],
        class: '',
        display_name: '',
        icon: '',
        importance: 0,
        lat: '',
        licence: '',
        lon: '',
        osm_id: 0,
        osm_type: '',
        place_id: 0,
        type: '',
        address: {
            country: '',
            country_code: '',
            county: '',
            house_number: '',
            postcode: '',
            state: '',
            suburb: '',
            city: '',
            neighbourhood: '',
            road: '',
            town: '',
            village: '',
        },
    },
};

const orderTypeReducer = (state: OrderTypeState = initialState, action: AnyAction): OrderTypeState => {
    if (setOrderType.match(action)) {
        const { orderType } = action.payload;
        return {
            ...state,
            orderType,
        };
    }
    if (checkOrderType.match(action)) {
        const { previousRoute, deliveryAvailable, showDialog } = action.payload;

        const isDelivery = state.orderType === OrderType.Delivery;
        const needToCheckDelivery =
            !(previousRoute.includes('discover') || previousRoute.includes('checkout')) && !isDelivery;

        const needToCheck = needToCheckDelivery && deliveryAvailable && !state.hasBeenChecked;

        return {
            ...state,
            needToCheck,
            showDialog,
            hasBeenChecked: true && showDialog,
        };
    }
    if (setCheckedOrderType.match(action)) {
        return {
            ...state,
            hasBeenChecked: true,
        };
    }
    if (setPlace.match(action)) {
        const { place } = action.payload;
        return {
            ...state,
            place,
        };
    }
    if (setPlaceAndType.match(action)) {
        const { place, orderType } = action.payload;
        return {
            ...state,
            place,
            orderType,
        };
    }
    return state;
};

export default orderTypeReducer;
