import { RESTAURANT_ACTION_TYPES } from './restaurant.actions';
import { RestaurantAction, RestaurantState } from './restaurant.type';

const initialState: RestaurantState = {
    restaurants: [],
    lastUpdated: 0,
    error: null,
    pending: false,
};

const restaurantReducer = (state: RestaurantState = initialState, action: RestaurantAction): RestaurantState => {
    switch (action.type) {
        case RESTAURANT_ACTION_TYPES.GET_RESTAURANTS_PENDING:
            return {
                ...state,
                pending: true,
                error: null,
            };
        case RESTAURANT_ACTION_TYPES.GET_RESTAURANTS_SUCCESS:
            return {
                ...state,
                pending: false,
                lastUpdated: Date.now(),
                restaurants: action.restaurants ? action.restaurants : [],
                error: null,
            };
        case RESTAURANT_ACTION_TYPES.GET_RESTAURANTS_ERROR:
            return {
                ...state,
                restaurants: [],
                pending: false,
                lastUpdated: Date.now(),
                error: action.error,
            };
        case RESTAURANT_ACTION_TYPES.UPDATE_RESTAURANTS:
            return {
                ...state,
                restaurants: action.restaurants ? action.restaurants : [],
                error: null,
            };
    }
    return state;
};

export default restaurantReducer;
