import React, { useEffect, useState } from 'react';

import { RestaurantDetail, OrderType, Coupon } from '@bestelleck/utils';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Close } from '@mui/icons-material';
import { Card, CardContent, Divider, IconButton, Collapse, Button } from '@mui/material';
import { FiShoppingBag } from 'react-icons/fi';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { CartItem } from '../../../redux/cart/types';
import { RootState } from '../../../redux/store';
import { useScrollBlock } from '../../../util/useScrollBlock';
import { calculateIsOpen, calculatePrice, formatPrice, isPreOrderCurrentlyPossible } from '../../../util/utils';
import CartItemComponent from '../CartItem/CartItem';
import SelectOrderDialog from '../SelectOrderDialog/SelectOrderDialog';

import styles from './Cart.module.scss';
import { CartOverview } from './CartOverview/CartOverview';

interface CartProps {
    showButton: boolean;
    restaurant: RestaurantDetail;
    minimumOrderValue?: number;
    collapsible?: boolean;
    coupon?: Coupon;
    setCoupon?: (coupon: Coupon | undefined) => void;
    setBackdrop?: (open: boolean) => void;
    setMessage?: ({ isError, message }: { isError: boolean; message: string }) => void;
    readonly?: boolean;
}

const CartComponent: React.FC<CartProps> = ({
    showButton = true,
    collapsible = false,
    restaurant,
    minimumOrderValue = 0,
    setBackdrop,
    coupon,
    setCoupon,
    setMessage,
    readonly,
}) => {
    const { id } = useParams<{ id: string }>();
    const { trackEvent } = useMatomo();

    const carts = useSelector((state: RootState) => state.cart.carts, shallowEqual);
    const orderType = useSelector((state: RootState) => state.orderType.orderType, shallowEqual);
    const deliverySelected = orderType === OrderType.Delivery;
    const deliveryAvailable = restaurant.orderTypes.find((value) => value === 'delivery') ? true : false;
    const isDelivery = deliveryAvailable && deliverySelected;
    const menuCoupon = isDelivery ? restaurant?.menu.coupon?.delivery : restaurant?.menu.coupon?.pickup;

    const [blockScroll, allowScroll] = useScrollBlock();

    useEffect(() => () => allowScroll());

    const foundCart = carts?.find((cart: { restaurantId: string; items: CartItem[] }) => cart.restaurantId === id);
    const items: readonly CartItem[] = foundCart ? foundCart.items : [];

    const [expanded, setExpanded] = useState(false);

    const [openAdressDialog, setOpenAdressDialog] = useState(false);

    const isOpen = calculateIsOpen(restaurant, orderType);

    const cartItems = items.map((item) => {
        return <CartItemComponent key={item.cartId} isDelivery={isDelivery} item={item}></CartItemComponent>;
    });

    const changeToDelivery = () => {
        if (!isDelivery && isOpen) {
            trackEvent({
                action: 'Change to delivery',
                category: 'Cart',
            });
            if (!openAdressDialog) {
                setOpenAdressDialog(true);
            }
        }
    };

    let delivery;
    if (isDelivery && restaurant?.delivery) {
        delivery = restaurant.delivery.place;
    }

    const deliveryPrice = delivery ? delivery.fee : 0;
    const { price, discount, subtotal } = calculatePrice({
        items,
        orderType,
        deliveryCost: deliveryPrice,
        menuCoupon,
        feeThreshold: delivery?.freeThreshold,
        coupon,
    });

    useEffect(() => {
        if (coupon && setCoupon) {
            if (coupon.minimumOrderValue > subtotal) {
                if (setMessage) {
                    setMessage({
                        isError: true,
                        message: `Der Coupon kann nicht angewendet werden, da der Mindesbestellwert von ${formatPrice(
                            coupon.minimumOrderValue,
                        )} nicht erfüllt ist.`,
                    });
                }
                setCoupon(undefined);
            }
        }
    }, [coupon, subtotal, setCoupon, setMessage]);

    minimumOrderValue = delivery ? delivery.minimumOrderValue : minimumOrderValue;
    const isDeliveryAvailableNow = restaurant.orderTypes.find((value) => value === 'delivery')
        ? calculateIsOpen(restaurant, OrderType.PickUp)
        : false;
    const preOrderPossible = isPreOrderCurrentlyPossible(restaurant, isDelivery, isOpen);
    const difference = minimumOrderValue - (subtotal - discount);

    let isMinimumOrderValue = minimumOrderValue <= subtotal - discount;
    if (coupon && coupon.ignoreMinimumOrderValue) {
        isMinimumOrderValue = true;
    }
    const cart = (
        <div>
            {collapsible && (
                <div className={styles.close}>
                    <IconButton
                        aria-label="close"
                        className={styles.closeButton}
                        onClick={() => setExpanded(false)}
                        size="large"
                    >
                        <Close />
                    </IconButton>
                </div>
            )}
            <div className={!isDelivery && deliveryAvailable ? styles.cartItemsPickup : styles.cartItems}>
                {cartItems.length > 0 && cartItems}
                {cartItems.length === 0 && (
                    <div className={styles.emptyCart}>
                        <FiShoppingBag />
                        <span>Aktuell ist dein Warenkorb leer, wähle doch ein paar leckere Gerichte aus.</span>
                    </div>
                )}
            </div>
            <Divider />
            <CartOverview
                delivery={delivery}
                items={items}
                orderType={orderType}
                coupon={coupon}
                menuCoupon={menuCoupon}
            />
            <Divider />
            {!isOpen && !preOrderPossible && (
                <div>
                    {showButton && (
                        <Button variant="contained" color="primary" disabled={true}>
                            Das Restaurant ist aktuell geschlossen
                        </Button>
                    )}
                </div>
            )}
            {isMinimumOrderValue && cartItems.length > 0 && (isOpen || preOrderPossible) && (
                <Link to={`/${id}/checkout`}>
                    {showButton && (
                        <Button id="sumbitCart" variant="contained" color="primary" className={styles.cartButton}>
                            Bestellen
                        </Button>
                    )}
                </Link>
            )}
            {(!isMinimumOrderValue || cartItems.length === 0) && (isOpen || preOrderPossible) && (
                <div>
                    <div className={styles.orderText}>
                        {cartItems.length > 0 && (
                            <div>
                                Leider kannst du noch nicht bestellen. Der Mindestbestellwert liegt bei{' '}
                                {formatPrice(minimumOrderValue)}.
                                {difference > 0 && (
                                    <div className={styles.diff}>
                                        Differenzbetrag: {formatPrice(minimumOrderValue - (subtotal - discount))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {showButton && (
                        <Button variant="contained" color="primary" disabled={true} className={styles.cartButton}>
                            Bestellen
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
    const targetElement = document.querySelector('#app');

    if (readonly) {
        return (
            <div
                className={collapsible ? [styles.collapse, styles.cart].join(' ') : styles.cart}
                id="cart"
                onClick={() => {
                    setExpanded(!expanded);
                    if (collapsible) {
                        if (setBackdrop) {
                            setBackdrop(!expanded);
                        }
                        if (!expanded && targetElement) {
                            blockScroll();
                        } else if (targetElement) {
                            allowScroll();
                        }
                    }
                }}
            >
                <Card className={styles.smallCard}>
                    <CardContent className={styles.content}>
                        <CartOverview
                            delivery={delivery}
                            items={items}
                            orderType={orderType}
                            coupon={coupon}
                            menuCoupon={menuCoupon}
                            showItems={true}
                        />
                    </CardContent>
                </Card>
            </div>
        );
    }
    return (
        <div
            className={collapsible ? [styles.collapse, styles.cartSticky].join(' ') : styles.cartSticky}
            id="cart"
            onClick={() => {
                setExpanded(!expanded);
                if (collapsible) {
                    if (setBackdrop) {
                        setBackdrop(!expanded);
                    }
                    if (!expanded && targetElement) {
                        blockScroll();
                    } else if (targetElement) {
                        allowScroll();
                    }
                }
            }}
        >
            {(!collapsible || items.length > 0 || expanded) && (
                <Card className={styles.card}>
                    <CardContent className={styles.content}>
                        <div className={styles.header}>
                            {((expanded && collapsible) || !collapsible) && (
                                <div>
                                    <h1>Warenkorb</h1>
                                    {!collapsible && <Divider />}
                                    {!isDelivery && isDeliveryAvailableNow && (
                                        <div className={styles.pickupInfo}>
                                            Du bist dabei, eine Bestellung zur Abholung abzuschließen. Wenn Du keine
                                            Abholung möchtest, kannst du
                                            <span
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    changeToDelivery();
                                                }}
                                                className={styles.link}
                                            >
                                                {' hier '}
                                            </span>
                                            auf Lieferung umstellen.
                                        </div>
                                    )}
                                </div>
                            )}
                            {collapsible && !expanded && (
                                <Button variant="contained" color="primary" size="large" className={styles.cartButton}>
                                    Zum Warenkorb ({formatPrice(price)})
                                </Button>
                            )}
                        </div>

                        {collapsible && (
                            <Collapse className={styles.collapse} in={expanded} timeout="auto" unmountOnExit>
                                {cart}
                            </Collapse>
                        )}
                        {!collapsible && cart}
                    </CardContent>
                </Card>
            )}
            {openAdressDialog && (
                <SelectOrderDialog
                    handleClose={() => setOpenAdressDialog(false)}
                    open={openAdressDialog}
                    restaurant={restaurant}
                />
            )}
        </div>
    );
};

export default CartComponent;
