import React, { useState } from 'react';

import { RestaurantDetail, OrderType, Place } from '@bestelleck/utils';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Button, DialogContent, styled, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { FiShoppingBag } from 'react-icons/fi';
import { IoCarOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';

import { AddressComponent } from '../../../components/Address/Address';
import { DialogTitle } from '../../../components/DialogTitle/DialogTitle';
import { setCheckedOrderType, setOrderType, setPlaceAndType } from '../../../redux/orderType/orderType.actions';
import { RootState } from '../../../redux/store';
import { useTrackView } from '../../../util/tracking/trackPage';

import styles from './SelectOrderDialog.module.scss';

type SelectOrderDialogProps = {
    open: boolean;
    restaurant: RestaurantDetail;
    handleClose: () => void;
};

const StyleDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        overflowY: 'visible',
    },
}));

const SelectOrderDialog: React.FC<SelectOrderDialogProps> = ({ open, restaurant, handleClose }) => {
    const dispatch = useDispatch();
    const { trackEvent } = useMatomo();
    useTrackView('Select_Order_Type');
    const orderType = useSelector((state: RootState) => state.orderType.orderType);
    const [value, setValue] = useState(orderType);
    const smallScreen = useMediaQuery('(max-width:750px)');

    return (
        <div>
            <StyleDialog
                onClose={() => {
                    trackEvent({
                        action: `Close Order Type dialog`,
                        category: 'Order Type',
                    });
                    handleClose();
                }}
                aria-labelledby="customized-dialog-title"
                id="selectOrderDialog"
                open={open}
                fullWidth
                fullScreen={smallScreen}
                maxWidth="sm"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => {
                        trackEvent({
                            action: `Close Order Type dialog`,
                            category: 'Order Type',
                        });
                        handleClose();
                    }}
                >
                    Abholung oder Lieferung?
                </DialogTitle>
                <DialogContent dividers>
                    <div className={styles.section}>
                        <div className={styles.header}>Bitte wähle:</div>
                        <div className={styles.buttons}>
                            <Button
                                variant={value === 'pickup' ? 'contained' : 'outlined'}
                                className={styles.leftButton}
                                startIcon={<FiShoppingBag />}
                                onClick={() => {
                                    setValue(OrderType.PickUp);
                                }}
                            >
                                Abholung
                            </Button>
                            <Button
                                variant={value === 'delivery' ? 'contained' : 'outlined'}
                                color="primary"
                                className={styles.rightButton}
                                startIcon={<IoCarOutline style={{ fontSize: 24 }} />}
                                onClick={() => {
                                    setValue(OrderType.Delivery);
                                }}
                            >
                                Lieferung
                            </Button>
                        </div>
                        {value === 'delivery' && restaurant.delivery && (
                            <div className={styles.address}>
                                <AddressComponent
                                    restaurant={restaurant}
                                    showHelp={true}
                                    onSelect={(place: Place) => {
                                        dispatch(setPlaceAndType({ place, orderType: OrderType.Delivery }));
                                        handleClose();
                                    }}
                                />
                            </div>
                        )}

                        {value === 'pickup' && (
                            <div className={styles.buttons2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    id="selectOrderType"
                                    className={styles.button}
                                    onClick={() => {
                                        trackEvent({
                                            action: `Confirmed pickup postal code`,
                                            category: 'Order Type',
                                        });
                                        dispatch(setOrderType({ orderType: OrderType.PickUp }));
                                        dispatch(setCheckedOrderType());
                                        handleClose();
                                    }}
                                >
                                    Übernehmen
                                </Button>
                            </div>
                        )}
                    </div>
                </DialogContent>
            </StyleDialog>
        </div>
    );
};

export default SelectOrderDialog;
