import { PaymentOption } from '@bestelleck/utils';
import { AnyAction } from '@reduxjs/toolkit';

import { RestaurantTags } from '../../types/Restaurant';

import {
    addTag,
    removeTag,
    setContact,
    setCurrentRoute,
    setFilter,
    setOrder,
    setPaymentMethods,
    setRated,
    setSearch,
    setSelectedPaymentMethod,
    setSelectedRestaurant,
} from './app.actions';
import { AppState } from './app.types';

const initialState: AppState = {
    selectedTags: [RestaurantTags.ALL],
    search: '',
    route: {
        previous: '',
        current: '',
    },
    selectedPaymentOption: PaymentOption.Cash,
    filters: {
        rating: 'none',
        payment: 'none',
        deliveryCost: -1,
        minimumOrderValue: -1,
    },
    selectedRestaurant: undefined,
    contact: {
        name: undefined,
        email: undefined,
        phone: undefined,
        address: undefined,
        city: undefined,
    },
    order: null,
    version: 0,
    cartVersion: 1,
    hasRated: false,
    paymentOptions: {
        google: false,
        apple: false,
        link: false,
    },
};

const appReducer = (state: AppState = initialState, action: AnyAction): AppState => {
    if (setCurrentRoute.match(action)) {
        const { route } = action.payload;
        return {
            ...state,
            route: {
                previous: state.route?.current || '',
                current: route,
            },
        };
    }

    if (setFilter.match(action)) {
        const { attribute, value } = action.payload;
        const filters = { ...(state.filters as any) };
        filters[attribute] = value;
        return {
            ...state,
            filters,
        };
    }

    if (setOrder.match(action)) {
        const { order } = action.payload;
        return {
            ...state,
            order: order,
        };
    }

    if (setSearch.match(action)) {
        const { query } = action.payload;
        return {
            ...state,
            search: query,
        };
    }

    if (addTag.match(action)) {
        const { tag } = action.payload;
        if (tag === RestaurantTags.ALL) {
            return {
                ...state,
                selectedTags: [tag],
            };
        }
        return {
            ...state,
            selectedTags: [tag],
        };
    }

    if (removeTag.match(action)) {
        const { tag } = action.payload;
        const selectedTags = state.selectedTags.filter((selectedTag) => selectedTag !== tag);
        if (selectedTags.length === 0) {
            selectedTags.push(RestaurantTags.ALL);
        }
        return {
            ...state,
            selectedTags,
        };
    }

    if (setContact.match(action)) {
        const { contact } = action.payload;
        if (contact !== undefined) {
            return {
                ...state,
                contact,
            };
        }
        return {
            ...state,
        };
    }

    if (setRated.match(action)) {
        return {
            ...state,
            hasRated: true,
        };
    }

    if (setSelectedRestaurant.match(action)) {
        const { restaurant } = action.payload;
        return {
            ...state,
            selectedRestaurant: restaurant,
        };
    }

    if (setPaymentMethods.match(action)) {
        const { options } = action.payload;
        return {
            ...state,
            paymentOptions: options,
        };
    }

    if (setSelectedPaymentMethod.match(action)) {
        const { option } = action.payload;
        return {
            ...state,
            selectedPaymentOption: option,
        };
    }

    return state;
};

export default appReducer;
