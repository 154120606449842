import React from 'react';

import { useTrackView } from '../../util/tracking/trackPage';

import Filter from './Filter/Filter';
import styles from './Main.module.scss';
import Overview from './Overview/Overview';

const Main: React.FC = () => {
    useTrackView('Discover');

    return (
        <div className={styles.Main}>
            <div className="filter">
                <Filter></Filter>
            </div>
            <div className={styles.overview}>
                <Overview></Overview>
            </div>
        </div>
    );
};

export default Main;
