import React, { useEffect, useState } from 'react';

import { LocationOnOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '../../../redux/store';
import SelectOrderDialog from '../../../screens/Restaurant/SelectOrderDialog/SelectOrderDialog';
import AddressDialog from '../../AddressDialog/AddressDialog';

import styles from './PostalCode.module.scss';

export const PostalCodeComponent: React.FC = () => {
    const url = useLocation();
    const isDiscover = url.pathname === '/discover';

    const { place } = useSelector((state: RootState) => state.orderType);

    const restaurant = useSelector((state: RootState) => state.app.selectedRestaurant);
    const hasDelivery = restaurant?.delivery ? true : false;

    const [showSelectDialog, setShowSelectDialog] = useState(false);
    const [showPostalDialog, setShowPostalDialog] = useState(false);

    const [location, setLocation] = useState(place.customDisplayName);
    useEffect(() => {
        setLocation(place.customDisplayName);
    }, [place]);

    useEffect(() => {
        return () => {
            setShowPostalDialog(false);
            setShowSelectDialog(false);
        };
    }, [url]);

    return (
        <>
            <div
                className={styles.root}
                onClick={() => {
                    if (isDiscover || !hasDelivery) {
                        setShowPostalDialog(true);
                    } else {
                        setShowSelectDialog(true);
                    }
                }}
            >
                <div className={styles.postalCodeContainer}>
                    <LocationOnOutlined className={styles.icon} />
                    <div className={styles.postalCode}>{location && location !== '' ? location : 'Deine Adresse'}</div>
                </div>
            </div>
            {showPostalDialog && (
                <AddressDialog
                    open={showPostalDialog}
                    handleClose={() => {
                        setShowPostalDialog(false);
                    }}
                ></AddressDialog>
            )}
            {showSelectDialog && restaurant && (
                <SelectOrderDialog
                    restaurant={restaurant}
                    handleClose={() => {
                        setShowSelectDialog(false);
                    }}
                    open={showSelectDialog}
                ></SelectOrderDialog>
            )}
        </>
    );
};
