import React, { useEffect, useState } from 'react';

import { convertDateToTime, getOpeningHoursForDate, OrderType, PaymentOption, Restaurant } from '@bestelleck/utils';
import { Tune } from '@mui/icons-material';
import { IconButton, Skeleton, useMediaQuery } from '@mui/material';
import { IoLocationOutline } from 'react-icons/io5';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import { SkeletonComponent } from '../../../components/Skeleton/Skeleton';
import { fetchRestaurants } from '../../../redux/restaurant/restaurant.api';
import { RootState } from '../../../redux/store';
import { RestaurantTags, Tag } from '../../../types/Restaurant';
import { baseUrl } from '../../../util/constants';
import { sortByDistance } from '../../../util/restaurant';
import { useHorizontalScroll } from '../../../util/scroll/horizontalScroll';
import { calculateDistance, getUniqueKey, calculateIsOpen, handleErrors } from '../../../util/utils';
import FilterDialog from '../Filter/FilterDialog/FilterDialog';
import TokenComponent from '../Filter/Token/Token';

import styles from './Overview.module.scss';
import RestaurantCard from './RestaurantCard/RestaurantCard';

const Overview: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const [openFilter, setOpenFilter] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:669px)');
    const scrollRef = useHorizontalScroll();

    const orderType = useSelector((state: RootState) => state.orderType.orderType, shallowEqual);
    const selectedTags: string[] = useSelector((state: RootState) => state.app.selectedTags, shallowEqual);

    const selectedFilters = useSelector((state: RootState) => state.app.filters, shallowEqual);

    const restaurantState = useSelector((state: RootState) => state.restaurant, shallowEqual);

    const { place } = useSelector((state: RootState) => state.orderType, shallowEqual);

    useEffect(() => {
        if (orderType === OrderType.Delivery && place.lat !== '' && place.lon !== '') {
            dispatch(fetchRestaurants(place.lat, place.lon, orderType));
        } else {
            dispatch(fetchRestaurants(place.lat, place.lon, orderType));
        }
    }, [dispatch, place, orderType]);

    const [restaurantTags, setRestaurantTags] = useState<Tag[]>([]);

    const url = `${baseUrl}/tags/restaurants`;
    useEffect(() => {
        fetch(url)
            .then(handleErrors)
            .then((res) => res.json())
            .then((result) => {
                setRestaurantTags([{ id: 'ALL', name: 'Alle' }, ...result]);
            });
    }, [url]);

    const isDelivery = orderType === OrderType.Delivery;

    const filteredAndCalculatedDistance = restaurantState.restaurants.map((restaurant) => {
        const distance = calculateDistance(
            { longitude: place.lon, latitude: place.lat },
            {
                longitude: restaurant.contact.longitude,
                latitude: restaurant.contact.latitude,
            },
        );
        return {
            ...restaurant,
            distance,
        };
    });

    const appliedFilters = filteredAndCalculatedDistance.filter((restaurant) => {
        let isMinimumOrderValue = true;
        let isDeliveryCost = true;
        let isPayment: boolean;
        let isRating: boolean;

        if (isDelivery && restaurant.delivery && restaurant.delivery.place) {
            const place = restaurant.delivery.place;
            if (selectedFilters.minimumOrderValue === -1) {
                isMinimumOrderValue = true;
            } else if (place.minimumOrderValue <= selectedFilters.minimumOrderValue) {
                isMinimumOrderValue = true;
            } else {
                isMinimumOrderValue = false;
            }

            if (selectedFilters.deliveryCost === -1) {
                isDeliveryCost = true;
            } else if (selectedFilters.deliveryCost === 0 && place.fee === 0) {
                isDeliveryCost = true;
            } else if (selectedFilters.deliveryCost >= place.fee) {
                isDeliveryCost = true;
            } else {
                isDeliveryCost = false;
            }
        }

        if (selectedFilters.rating === 'none') {
            isRating = true;
        } else if (
            Number(selectedFilters.rating) <= Math.round(restaurant.ratings.average) ||
            restaurant.ratings.average === 0
        ) {
            isRating = true;
        } else {
            isRating = false;
        }

        if (selectedFilters.payment === 'none') {
            isPayment = true;
        } else if (selectedFilters.payment === 'cash') {
            isPayment = restaurant.payment.paymentOptions.includes(PaymentOption.Cash);
        } else {
            isPayment =
                restaurant.payment.paymentOptions.includes(PaymentOption.PayPal) ||
                restaurant.payment.paymentOptions.includes(PaymentOption.StripeCreditCard);
        }

        return isMinimumOrderValue && isDeliveryCost && isPayment && isRating;
    });

    const filteredRestaurants = appliedFilters.filter((restaurant: Restaurant) => {
        const filter = orderType === OrderType.Delivery ? 'delivery' : 'pickup';
        if (selectedTags.includes(RestaurantTags.ALL)) {
            return restaurant.orderTypes.includes(filter as OrderType);
        }
        const isIncludedinTags = selectedTags.some((tag) => restaurant.tags.includes(tag));

        if (isIncludedinTags) {
            return true;
        }
        return false;
    });

    const currentlyOpenRestaurants = [];
    const todayOpenRestaurants = [];
    const preOrderRestaurants = [];
    const closedRestaurants = [];
    for (const restaurant of filteredRestaurants) {
        const currentDate = new Date();
        const currentTime = convertDateToTime(currentDate);
        const isOpen = calculateIsOpen(restaurant, orderType);
        const openingSchedule = isDelivery ? restaurant.delivery : restaurant.pickup;
        const hasPreOrder = isDelivery ? restaurant.delivery.supportsPreOrder : restaurant.pickup.supportsPreOrder;
        const nextOpeningFrame = getOpeningHoursForDate(
            openingSchedule.days,
            openingSchedule.exceptions,
            currentDate,
        )?.find((frame) => frame.from > currentTime);
        if (isOpen) {
            currentlyOpenRestaurants.push(restaurant);
        } else if (!isOpen && nextOpeningFrame) {
            if (hasPreOrder) {
                preOrderRestaurants.push(restaurant);
            } else {
                todayOpenRestaurants.push(restaurant);
            }
        } else {
            closedRestaurants.push(restaurant);
        }
    }

    const filteredAndSortedRestaurants = [
        ...currentlyOpenRestaurants.sort(sortByDistance),
        ...preOrderRestaurants.sort(sortByDistance),
        ...todayOpenRestaurants.sort(sortByDistance),
        ...closedRestaurants.sort(sortByDistance),
    ];

    let list;
    if (restaurantState.pending) {
        list = Array.from(new Array(6)).map((_, index) => {
            return <SkeletonComponent key={index} className={styles.root} />;
        });
    } else {
        list = filteredAndSortedRestaurants.map((restaurant) => {
            return (
                <Link
                    to={{
                        pathname: `/${restaurant.id}`,
                        state: { restaurantMetadata: restaurant },
                    }}
                    key={getUniqueKey()}
                >
                    <RestaurantCard
                        isDelivery={isDelivery}
                        place={restaurant.delivery?.place || undefined}
                        restaurant={restaurant}
                        restaurantTags={restaurantTags}
                    />
                </Link>
            );
        });
    }

    const tokens = restaurantTags.map((tag) => {
        return (
            <div key={getUniqueKey()}>
                <TokenComponent tag={tag}></TokenComponent>
            </div>
        );
    });

    const selectedTag: Tag | undefined = restaurantTags.find((tag) => tag.id === selectedTags[0]);
    const selectedTagDisplay = selectedTag?.id === 'ALL' ? '' : selectedTag?.name;

    return (
        <div className={styles.container}>
            <div className={styles.filterAndTokens}>
                {isSmallScreen && (
                    <div className={styles.filter}>
                        <IconButton aria-label="delete" onClick={() => setOpenFilter(true)}>
                            <Tune />
                        </IconButton>
                        <FilterDialog open={openFilter} handleClose={() => setOpenFilter(false)} />
                    </div>
                )}
                <div ref={scrollRef} className={styles.tokens}>
                    {tokens}
                </div>
            </div>
            <div className={styles.Overview}>
                {list.length > 0 && !restaurantState.error && (
                    <>
                        <h3 style={{ display: 'flex' }}>
                            Bestelle von{' '}
                            {restaurantState.pending ? (
                                <Skeleton
                                    animation="wave"
                                    width={17}
                                    style={{ margin: -5, marginLeft: 5, marginRight: 5 }}
                                />
                            ) : (
                                filteredAndSortedRestaurants.length
                            )}{' '}
                            Restaurants
                        </h3>

                        <div className={styles.restaurants}>
                            {list}
                            <div className={styles.placeHolder}></div>
                            <div className={styles.placeHolder}></div>
                            <div className={styles.placeHolder}></div>
                            <div className={styles.placeHolder}></div>
                            <div className={styles.placeHolder}></div>
                        </div>
                    </>
                )}
                {restaurantState.error && (
                    <div className={styles.notFound}>
                        <div>
                            <IoLocationOutline />
                        </div>

                        <div>Beim Laden ist ein Fehler aufgetreten, bitte versuche es erneut. </div>
                    </div>
                )}
                {list.length === 0 && !restaurantState.error && (
                    <div className={styles.notFound}>
                        <div>
                            <IoLocationOutline />
                        </div>
                        {restaurantState.error && (
                            <div>Beim Laden ist ein Fehler aufgetreten, bitte versuche es erneut. </div>
                        )}
                        {place.customDisplayName && !restaurantState.error && (
                            <div>
                                {`Ups, wir konnten leider keine Restaurants finden die ${selectedTagDisplay} zu dir liefern :(`}
                            </div>
                        )}
                        {!place.customDisplayName && !restaurantState.error && (
                            <div>Um Restaurants in deiner Nähe zu finden, gebe bitte oben deine Adresse an.</div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Overview;
